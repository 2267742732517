import React from "react";
import vylogo from "../image/vy-logo.png";
import "../styles/Header.css";
function Header() {
  const clearsession = () => {
    sessionStorage.clear("email");
  };
  return (
    <>
      <nav>
        <div className="logo">
          <a href="/">
            <img
              src={vylogo}
              width="100px"
              alt="nothing"
              className="vysystemlogo"
            />
          </a>
        </div>

        <ul>
          <li>
            <a className="active" href="/">
              Home
            </a>
          </li>
          <li className="dropdown">
            <a href="/" className="dropbtn">
              Candidate
            </a>
            <div className="dropdown-content">
              <a href="/candidate-details">Candidate Creation</a>
              <a href="/candidate-filelist">Candidate list</a>
            </div>
          </li>
          <li>
            <a href="/" onClick={() => clearsession()}>
              <div className="logout">Logout</div>
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Header;
