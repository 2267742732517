/* eslint-disable no-unused-expressions */
import { styled } from "@mui/material/styles";
import {  Grid } from "@mui/material";
import DatePicker from "react-date-picker";
('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

export const StyledDatePicker=styled(DatePicker)
`
.react-date-picker__wrapper {
    display: flex;
    flex-grow: 8;
    flex-shrink: 8;
    border: 1px solid #B0A6BA;
    padding: 4px 5px;
    border-radius: 4px;
    background: white;
    margin-top: 6px;
    margin-right: 3px;
}
 @media screen and (min-width:2000px) and (max-width:3000px) {
  .react-date-picker__wrapper {
    margin-top: 20px;
 
}
}
@media screen and (min-width:4000px) and (max-width:5000px)
{
    .react-date-picker__wrapper
    {
        margin-top:30px!important;
    }
}
// @media screen and (min-width:1800px)  and  (max-width:1999px)
// {
//         margin-top:10px;
//         padding:8px 2px!important;
// }
// @media screen and (min-width:2000px) and (max-width:3000px)
// {
//     margin-top:20px!important;
//     padding:10px 15px;
   
// }
@media screen and (max-width:600px)
{
    .react-date-picker__wrapper {
        width: 40%;
    }
}
@media screen and (min-width:350px) and (max-width:499px)
{
    .react-date-picker__wrapper {
        width: 40%;
    }
}
}
@media only screen and (min-width:713px) and (max-width: 992px){
    .react-date-picker__wrapper {
        width: 350px;
    }

#root .react-date-picker {
    display: inline-flex;
    position: relative;
    max-width: 100%;
    width: 47%;
  }
react-date-picker__inputGroup__input:invalid {
    background: rgb(252 245 245 / 10%);
}
.react-date-picker__inputGroup__input {
    border: 0;
    background: none;
    color: black;
    font: message-box;
    box-sizing: content-box;
    appearance:none;
}
input.react-date-picker__inputGroup__input:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}
@media screen and (min-width:2000px) and (max-width:2643px)
{
     .react-date-picker
    {
        margin-top:20px!important;
    }
//     .react-date-picker__wrapper
//     {
//         margin-top:20px!important
//     }
   .react-date-picker__wrapper
  {
    margin-top:20px!important;
   }
 }


`
export const MainDivContainer = styled(Grid)
`
height:100vh;
width:100%;
`
export  const Note =styled("p")
`
color:red;
font-size:18px;
@media  screen and (min-width:4000px) and (max-width:5000px)
{
font-size:30px;
}
`

export const CandidateDetailsh3 = styled("h3")`
  text-align: center;
    font-size: 17px;
    font-weight: 600;
    font-family: 'Outfit', sans-serif;
    margin-top: 0;
    position: relative;
    top: 30px;
    left: 20px;
    background: linear-gradient(180deg, #FFF 0%, #F3F4FB 100%);
    width: 13%;
    padding: 0 2px;
    color: #451B70;
    @media only screen and (max-width: 1325px) {

            text-align: left;
            font-size: 17px;
            font-weight: 600;
            font-family: sans-serif;
            margin-top: 0;
            position: relative;
            top: 14px;
            left: 0;
            background: transparent;
            width: 100%;
            padding: 0px 0px;
            color: #451B70;
   
    }
    @media screen and (min-width:1400px) and (max-width:1799px)
    {
        font-size:20px;
    }
    @media screen and (min-width:1800px)  and  (max-width:1999px)
    {
        font-size:22px;

    }
@media  screen and (min-width:2000px) and (max-width:3000px)
{
    font-size:25px; 
}
@media screen and (min-width: 4000px) and (max-width: 5000px) {
    font-size: 35px;
    top: 42px;
    left: 2px;
  }
@media screen and (min-width:5001px) and (max-width:6000px)
{
    font-size:30px;
    top: 42px;
    left: 4px;

}
`
export const I9Detailsh3 = styled("h3")`
text-align: center;
    font-size: 17px;
    font-weight: 600;
    font-family: 'Outfit', sans-serif;
    margin-top: 0;
    position: relative;
    top: 30px;
    left: 20px;
    background: linear-gradient(180deg, #FFF 0%, #F3F4FB 100%);
    width: 12%;
    padding: 0 2px;
    color: #451B70;

    @media only screen and (max-width: 1325px) {

        text-align: left;
        font-size: 17px;
        font-weight: 600;
        font-family: 'Outfit', sans-serif;
        margin-top: 0;
        position: relative;
        top: 14px;
        left: 0;
        background: transparent;
        width: 100%;
        padding: 0px 0px;
        color: #451B70;

}
@media screen and (min-width:1400px) and (max-width:1799px)
{
    font-size:20px;
}
@media screen and (min-width:1800px)  and  (max-width:1999px)
{
    font-size:25px;

}
@media screen and (min-width:2000px) and (max-width:3000px)
{
    font-size:25px
}
@media screen and (min-width: 4000px) and (max-width: 5000px) {
    font-size: 35px;
    top: 42px;
    left: 4px;
  }
  @media screen and (min-width:5001px) and (max-width:6000px)
{
    font-size:30px;
    top: 42px;
    left: 4px;
}
`
export const MSADetailsh3 = styled("h3")`
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    font-family: 'Outfit', sans-serif;
    margin-top: 0;
    position: relative;
    top: 30px;
    left: 20px;
    background: linear-gradient(180deg, #FFF 0%, #F3F4FB 100%);
    width: 13%;
    padding: 0 2px;
    color: #451B70;

    @media only screen and (max-width: 1325px) {

        text-align: left;
        font-size: 17px;
        font-weight: 600;
        font-family: 'Outfit', sans-serif;
        margin-top: 0;
        position: relative;
        top: 14px;
        left: 0;
        background: transparent;
        width: 100%;
        padding: 0px 0px;
        color: #451B70;

}
@media screen and (min-width:1400px) and (max-width:1799px)
{
    font-size:20px;
}
@media screen and (min-width:1800px)  and  (max-width:1999px)
{
    font-size:25px;

}
@media screen and (min-width:2000px) and (max-width:3000px)
{
    font-size:25px!important;
}
@media screen and (min-width: 4000px) and (max-width: 5000px) {
    font-size: 35px;
    top: 42px;
    left: 4px;
  }
  @media screen and (min-width:5001px) and (max-width:6000px)
{
    font-size:30px;
    top: 42px;
    left: 4px;

}

`
export const W9Detalish3 = styled("h3")`
text-align: center;
    font-size: 17px;
    font-weight: 600;
    font-family: 'Outfit', sans-serif;
    margin-top: 0;
    position: relative;
    top: 30px;
    left: 20px;
    background: linear-gradient(180deg, #FFF 0%, #F3F4FB 100%);
    width: 12%;
    padding: 0 2px;
    color: #451B70;

    @media only screen and (max-width: 1325px) {

        text-align: left;
        font-size: 17px;
        font-weight: 600;
        font-family: 'Outfit', sans-serif;
        margin-top: 0;
        position: relative;
        top: 14px;
        left: 0;
        background: transparent;
        width: 100%;
        padding: 0px 0px;
        color: #451B70;

}
@media screen and (min-width:1400px) and (max-width:1799px)
{
    font-size:20px;
}
@media screen and (min-width:1800px)  and  (max-width:1999px)
{
    font-size:25px;
}
@media screen and (min-width:2000px) and (max-width:3000px)
{
    font-size:25px;
}
@media screen and (min-width: 4000px) and (max-width: 5000px) {
    font-size: 35px;
    top: 42px;
    left: 4px;
  }
  @media screen and (min-width:5001px) and (max-width:6000px)
{
    font-size:30px;
    top: 42px;
    left: 4px;

}

`
export const VisaDetailsh3 = styled("h3")`
text-align: center;
    font-size: 17px;
    font-weight: 600;
    font-family: 'Outfit', sans-serif;
    margin-top: 0;
    position: relative;
    top: 30px;
    left: 20px;
    background: linear-gradient(180deg, #FFF 0%, #F3F4FB 100%);
    width: 13%;
    padding: 0 2px;
    color: #451B70;

    @media only screen and (max-width: 1325px) {
        text-align: left;
        font-size: 17px;
        font-weight: 600;
        font-family: 'Outfit', sans-serif;
        margin-top: 0;
        position: relative;
        top: 14px;
        left: 0;
        background: transparent;
        width: 100%;
        padding: 0px 0px;
        color: #451B70;

}
@media screen and (min-width:1400px) and (max-width:1799px)
{
    font-size:20px;
}
@media screen and (min-width:1800px)  and  (max-width:1999px)
{
    font-size:25px;

}
@media screen and (min-width:2000px) and (max-width:3000px)
{
    font-size:25px;
}
@media screen and (min-width: 4000px) and (max-width: 5000px) {
    font-size: 35px;
    top: 42px;
    left: 4px;
  }
  @media screen and (min-width:5001px) and (max-width:6000px)
{
    font-size:30px;
    top: 42px;
    left: 4px;

}

`
export const COIDeatailsh3 = styled("h3")`
text-align: center;
    font-size: 17px;
    font-weight: 600;
    font-family: 'Outfit', sans-serif;
    margin-top: 0;
    position: relative;
    top: 30px;
    left: 20px;
    background: linear-gradient(180deg, #FFF 0%, #F3F4FB 100%);
    width: 24%;
    padding: 0 2px;
    color: #451B70;

    @media only screen and (max-width: 1325px) {

        text-align: left;
        font-size: 17px;
        font-weight: 600;
        font-family: 'Outfit', sans-serif;
        margin-top: 0;
        position: relative;
        top: 14px;
        left: 0;
        background: transparent;
        width: 100%;
        padding: 0px 0px;
        color: #451B70;

}
@media screen and (min-width:1400px) and (max-width:1799px)
{
    font-size:19px;
}
@media screen and (min-width:1800px)  and  (max-width:1999px)
{
    font-size:21px;

}
@media screen and (min-width:2000px) and (max-width:3000px)
{
    font-size:25px!important;
}
@media screen and (min-width: 4000px) and (max-width: 5000px) {
    font-size: 35px;
    top: 42px;
    left: 4px;
  }
  @media screen and (min-width:5001px) and (max-width:6000px)
{
    font-size:30px;
    top: 42px;
    left: 4px;

}

`
export const CountersignDeatailsh3 = styled("h3")`
text-align: center;
    font-size: 17px;
    font-weight: 600;
    font-family: 'Outfit', sans-serif;
    margin-top: 0;
    position: relative;
    top: 30px;
    left: 20px;
    background: linear-gradient(180deg, #FFF 0%, #F3F4FB 100%);
    width: 19%;
    padding: 0 2px;
    color: #451B70;

    @media only screen and (max-width: 1325px) {

        text-align: left;
        font-size: 17px;
        font-weight: 600;
        font-family: 'Outfit', sans-serif;
        margin-top: 0;
        position: relative;
        top: 14px;
        left: 0;
        background: transparent;
        width: 100%;
        padding: 0px 0px;
        color: #451B70;

}
@media screen and (min-width:1400px) and (max-width:1799px)
{
    font-size:20px;
}
@media screen and (min-width:1800px)  and  (max-width:1999px)
{
    font-size:25px;

}
@media  screen and (min-width:2000px) and (max-width:3000px)
{
    font-size:25px;
}
@media screen and (min-width: 4000px) and (max-width: 5000px) {
    font-size: 35px;
    top: 42px;
    left: 4px;
  }
  @media screen and (min-width:5001px) and (max-width:6000px)
{
    font-size:30px;
    top: 42px;
    left: 4px;
}
`

export const StyledinputEmailContainer = styled("input")`
input[type=email],{
    width: 45%;
    height:0;
    padding: 17px 5px;
    border: 1px solid #B0A6BA;
    border-radius: 5px;
    margin-top: 6px;
    margin-bottom: 10px;
    font-size: 13px;
    &:focus {
        border-color: #683E93;
    outline: 2px solid #683E93;
    }
}

@media only screen and (max-width: 500px) {
    input[type=email],{
        width: 95%;
    }
}
@media screen and (min-width:601px) and (max-width:712px)
{
        font-size:16px!important;
        margin-top:10px;
    
}
@media screen and (min-width:1400px) and (max-width:1799px)
{
    font-size:14px!important;
    margin-top:10px;
    padding:17px 5px;
}
@media screen and (min-width:1800px)  and  (max-width:1999px)
{
    padding: 17px 5px;
    margin-top: 10px;
    font-size: 14px;

}
@media  screen and (min-width:2000px) and (max-width:3000px)
{
    padding: 20px 10px;
    margin-top: 20px;
    font-size: 20px;
}
@media screen and (min-width:4000px) and (max-width:5000px)
{
    margin-top:30px;
    padding:18px 15px;
    font-size:25px;
}
@media screen and (min-width:5001px) and (max-width:6000px)
{
    font-size:16px;
    margin-top:20px;
}
`
export const StyledinputTextContainer = styled("input")`
input[type=text],{
    width: 45%;
    height:0;
    padding: 17px 5px;
    border: 1px solid #B0A6BA;
    border-radius: 5px;
    margin-top: 6px;
    margin-bottom: 10px;
    font-size: 13px;
    cursor: pointer;
    &:focus {
        border-color: #683E93;
        outline: 2px solid #683E93;
      }
}

@media only screen and (max-width: 500px) {
    input[type=text],{
        width: 95%;
    }
}
@media screen and (min-width:601px) and (max-width:712px)
{
    font-size:15px;
    margin-top:10px;
}
@media screen and (min-width:1400px) and (max-width:1799px)
{
    margin-top:10px;
    padding:17px 5px;
    font-size:14px;
}
@media  screen and (min-width:1800px) and (max-width:1999px)
{
    padding:17px 5px;
    margin-top:10px;
    font-size:14px;   
}
@media  screen and (min-width:2000px) and (max-width:3000px)
{
    padding:20px 10px;
    margin-top:20px;
    font-size:20px;
}
@media screen and (min-width:4000px) and (max-width:5000px)
{
    margin-top:30px;
    padding:20px 15px;
    font-size:25px;
}

`
export const StyledDocumantaionContainer =styled("input")`
input[type=text],{
    width: 45%;
    height:0;
    padding: 17px 5px;
    border: 1px solid #B0A6BA;
    border-radius: 5px;
    margin-top: 6px;
    margin-bottom: 10px;
    font-size: 13px;
    cursor: pointer;

    
}

@media only screen and (max-width: 500px) {
    input[type=text],{
        width: 95%;
    }
}
@media screen and (min-width:2000px) and (max-width:2643px)
{
    input[type=text],{
        margin-top:20px;
    }
}
@media screen and (min-width:4000px) and (max-width:5000px)
{
    width:47%;
    margin-top:25px!important;
    font-size:30px;
    padding: 26px 5px;
}
`
export const StyledDateInput = styled("input")`
input[type=date],{
    width: 100%;
    height: 20px;
    outline: none;
    border: none;
    font-size: 13px;
    cursor: pointer;
}

&:focus {
    border-color: #683E93;
}

`
export const StyledCandidateMainDiv = styled("div")
`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    border: 1px solid #E4DAEE;
    background-color:#F3F4FB;
    padding: 22px;
    border-radius: 9px;
    row-gap: 25px;
    margin-bottom: 25px;

    @media only screen and (max-width: 1025px) {
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-12.MuiGrid-grid-lg-12.css-1idn90j-MuiGrid-root {
        overflow-x: hidden !important;
    }
    
}
@media screen and (min-width:1330px) and (max-width:1374px)
{
   
        // background-color:aqua
           max-width:100%;
        // overflow-x: hidden;

}
@media  screen and (min-width:250px) and (max-width: 600px) {
        
    display: grid;
    grid-template-columns: 1fr;
}
@media screen and (min-width:601px) and (max-width:712px)
{
    display:grid;
    grid-template-columns:1fr;

}
    @media screen and (min-width:713px) and  (max-width: 991px) 
    {
        display: grid;
        grid-template-columns: 1fr!important;
    }
    @media  screen and (min-width :992px) and (max-width:1024px)
    {
        display: grid;
        grid-template-columns: 1fr;
    }
    @media screen and (min-width:1400px) and (max-width:1440px)
    {
        display:grid;
        grid-template-columns:1fr!important;
    }
  

    .i-9 {
        margin:0px !important;
    }

    .file-input-container {
        position: relative;
        width: 270px;
        height: 25px;
        border: 1px solid #ccc;
        border-radius: 5px;
        overflow: hidden;
        width: 80%;
        padding: 5px 5px;
        border: 1px solid #B0A6BA;
        border-radius: 5px;
        margin-top: 6px;
        margin-bottom: 10px;
        font-size: 12px;
        background-color: white;
        &:focus-within {
            border-color:#683E93;
            outline:2px solid #683E93;
        }
 
    }
   
   `
export const Styledheadings=styled("h3")
`
color: #626262;
font-size: 14px;
width: 50%;
text-align: left;
// @media only screen and (max-width: 1170px) {
//     font-size:13px;
// }
@media screen and (min-width:601px) and (max-width:712px)
{
    font-weight:600;
    font-size:15px;
}
@media screen and (min-width:1400px) and (max-width:1799px)
{
    font-size:16px !important;
}
@media screen  and (min-width:1800px) and (max-width:1999px)
{
        font-size:18px!important;
}
@media  screen and (min-width:2000px) and (max-width:3000px)
{
    font-size:22px;
   
    .css-1il3n3s {
        font-size:22px
    }
}
@media screen and (min-width:4000px) and (max-width:5000px)
{
    font-size:30px;
}
@media screen and (min-width:5001px) and (max-width:6000px)
{
    font-size:28px;
}
@media only screen and (max-width: 500px) {
    width: 100%;
}

`
export const StyledSpanStar=styled("span")`
color: red;

`
export const StyledSelectOption=styled("select")`
width: 47%;
height: 40px;
padding: 0px 5px;
border: 1px solid #B0A6BA;
    border-radius: 5px;
    margin-top: 6px;
    margin-bottom: 10px;
    cursor: pointer;

    &:focus {
        border-color: #683E93;
    outline: 2px solid #683E93;
      }

      @media only screen and (max-width: 499px) {
            width: 95%;
    }
    @media screen and (min-width:500px) and (max-width:600px)
    {
        width:100%;
    }
    @media screen and (min-width:1400px) and (max-width:1799px)
    {
        font-size:14px;
    }
    @media screen and (min-width:1800px) and (max-width:1999px)
    {
        font-size: 14px;
        padding: 2px 5px;
        margin-top: 10px;
    }
    @media screen and (min-width:2000px) and (max-width:3000px)
    {
        
        font-size: 20px;
        padding: 0px 20px;
        margin-top: 10px;
    }
    @media screen and (min-width:2000px) and (max-width:3000px)
    {
        .custom-select
        {
            font-size:20px;
            width:15%;
        }
    }
    @media screen and (min-width:4000px) and (max-width:5000px)
    {
       font-size:30px;
       height:60px;
    }

`
export const StyledTextArea=styled("textarea")`
width: 44%;
height: 100px;
resize: none;
border-radius: 5px;
border-color: #B0A6BA;
padding: 10px;
font-size: 15px;
font-family: system-ui;
&:focus {
    border-color: #683E93;
outline: 2px solid #683E93;
}

@media only screen and (max-width: 1200px) {
    width: 42%;
}

@media only screen and (max-width: 991px) {
    width: 44%;
}
@media only screen and (max-width: 600px) {
    width: 42%;
}

@media only screen and (max-width: 500px) 
{
    width: 97%;
}
@media screen and (min-width:4000px) and (max-width:5000px)
{
    width:46%;
    font-size:30px;
}
`
export const StyledFileUploadButton=styled("button")
`

font-family: 'Outfit', sans-serif;
color: white !important;
border: 1px solid white;
text-transform: capitalize !important;
font-size: 14px !important;
font-weight: 500 !important;
margin: 0 !important;
padding: 11px 31px !important;
border-radius: 5px;
cursor: pointer;
// @media screen and (min-width:721px) and (max-width:991px)
// {
//     padding:21px 30px;
//     position:relative;
//     bottom:0;
// }
@media screen and (min-width:601px) and (max-width:712px)
{
    font-size:18px;
}
@media screen and (min-width:1400px) and (max-width:1799px)
{
    width:15%;
    font-size:20px;
}
@media screen and (min-width:1800px) and (max-width:1999px)
{
    width:20%;
    font-size:20px!important;

}
@media screen and (min-width:4000px)  and (max-width:5000px)
{
    width:16%;
    font-size:30px!important;
    padding:15px 31px;
}

`
export const StyledButtonDiv=styled("div")`
text-align: center;
margin-bottom: 10px;
    margin-top: 20px;
`
export const StyledOrderList=styled("ol")`

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;

`
export const StyledinputTextContainerComplete = styled(StyledinputTextContainer)`
  color: green;
  text-align: center;
  background-color:#E0FFE0;
`;

export const StyledinputTextContainerIncomplete = styled(StyledinputTextContainer)`
  color: red;
  text-align: center;
  background-color:#FFECEC;
`;

export const StyledFieldOuterDiv = styled("div")`
display: flex;
flex-wrap: wrap !important;
column-gap: 10px !important;
flex-wrap: nowrap;
justify-content: space-between;
align-items: flex-start;
overflow:hidden

 .MuiStack-root.css-1xhypcz-MuiStack-root {
    overflow: hidden;
 }
 .MuiStack-root.css-1pduc5x-MuiStack-root {
    overflow: hidden;
}
@media screen and  (min-width:1500px) and (max-width:1799px)
{
    .file-input-container
    {
        margin-top:10px;
    }
}
@media screen and (min-width:1400px) and (max-width:1440px)
{
    .file-input-container
    {
        width:47%;
    }

}
@media screen and (min-width:1800px) and (max-width:1999px)
{
    
    .file-input-container {
        margin-top: 10px;
     
    }
    .file-input-container label
    {
        font-size:16px!important;
    }
}
@media screen and (min-width:1800px) and (max-width:1999px)
{
    .error-message
    {
        font-size:15px!important;
    }


}
@media screen and (min-width:2000px) and (max-width:3000px)
{
    // .css-fhcqvg .file-input-container {
    //     margin-top:30px;
    // }
    .css-pic4dk {
        font-size:18px!important;
    }
    .css-1wip0s1 {
        font-size:18px;
    }
    .file-input-container {
        margin-top: 19px;
     
    }
    .file-input-container label
    {
        font-size:20px!important;
    }
    .css-k93lbn
    {
        font-size:22px
    }
    .css-hx4qds 
    {
        font-size:20px!important;
    }
    .css-s2anb4 
    {
        font-size:20px!important;
    }
    
    .error-message
    {
        font-size:20px!important;
    }
}
@media  screen and (min-width:4000px) and (max-width:5000px)
{
    .file-input-container
    {
        margin-top:20px;
        padding:12px 16px!important;
        
    }
    .file-input-container label
    {
        font-size:30px!important;
    }
    .error-message
    {
        font-size:30px!important;
    }

}
@media only screen and (max-width: 500px) {
    display: block !important;

    .error-message {
        left: 0 !important;
    }

    .file-input-container {
        width: 98% !important;
    }
}

@media screen and (min-width:601px) and (max-width:712px)
{
    .error-message
    {
        font-size:18px!important;
    }
}
.file-input-container {
    position: relative;
    width: 45%!important ;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    padding: 5px 5px;
    border: 1px solid #B0A6BA;
    border-radius: 5px;
    font-size: 13px;
}

.file-input-container label {
    position: absolute;
    top: 28px;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
}

.error-message {
    font-size: 12px;
    position: relative;
    top: 0px;
    left: 54%;
    color: red;
    font-weight: 600
}

h3.form-heading{
    position: relative;
}

h3.form-heading::before {
    position: absolute;
    content: ":";
    width: 10px;
    height: 20px;
    color: #000;
    z-index: 999;
    right: -10px;
}
@media screen and (min-width:250px) and (max-width:350px)
{
    h3.form-heading::before
    {
        content:"";
    }
   
}
@media screen and (min-width:351px) and (max-width:499px)
{
    h3.form-heading::before
    {
        content:"";
    }
}
@media screen and (min-width:500px) and (max-width:600px)
{
    .form-heading
    {
        width:100%;
    }
    input[type=email],
    {
        width:100%!important;
    }
    input[type=text], 
    {
        width:100%!important;
    }
    .react-date-picker {
        width:100% !important;
    }
    .file-input-container
    {
        width:100% !important;
    }
    #VisaCompany
    {
        width:100% !important;
    }
    #coi_expiry-type
    {
        width:100% !important;
    }
    .css-1uevala {
        width:100% !important;
    }
    #outlined-multiline-flexible
    {
        width:100% !important;
    }
    h3.form-heading::before
    {
        content:"";
    }
    .error-message
    {
        left:0;
    }
}
@media screen and (min-width:350px) and (max-width:600px)
{
   .file-input-container 

    {
        width:100%!important;
    }
    
}
@media screen and (min-width:250px) and (max-width:349px)
{
    .file-input-container 

    {
        width:100%!important;
    }
}
`