import React from "react";
import axios from "axios";
import * as url from "../assets/helpers/url_helper";
import config from "../../config";
import "../styles/dashboard.css";
import Header from "../header/Header";
import CancelIcon from "@mui/icons-material/Cancel";
import candidatelogo from "../image/candidate.png";
import completelogo from "../image/complete.png";
import incompletelogo from "../image/incomplete.png";
import expirylogo from "../image/expire.png";
import ExpiryScreen from "../Expiry-Login-Screen/ExpiryScreen";
import Footerfile from "./Footerfile";

const fileLogos = [candidatelogo, incompletelogo, completelogo, expirylogo];
function Dashboard(props) {
  const { TokenExpired, setTokenExpired } = props || {};
  const [data, setData] = React.useState([]);
  const [ExpiryDate, setExpiryDate] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  document.body.classList.add("salmon");
  const { i9 = [], coi = [], visa = [] } = ExpiryDate;

  console.log(data);
  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

    return `${formattedDate} `;
  }
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          config.apiEndpoint + url.GET_COUNT_BY_DATA
        );
        const filteredData = response.data.data;
        console.log(response);
        setData(filteredData);
      } catch (error) {
        console.log(error);

        if (
          error.response &&
          error.response.data &&
          error.response.data.message === "Token expired"
        ) {
          setTokenExpired(true);
        }
      }
    };
    fetchData();
  }, [setTokenExpired]);
  const getExpiryDate = async () => {
    try {
      const response = await axios.get(
        config.apiEndpoint + url.GET_EXPIRY_DATE_COUNT
      );
      setOpen(true);
      const filteredData = response.data.data;
      console.log(filteredData);

      setExpiryDate(filteredData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div className="main-container">
      {TokenExpired ? (
        <ExpiryScreen />
      ) : (
        <div className="header">
          <Header />
          <div className="container">
            {data.map((item, index) => {
              const fileLogo = fileLogos[index % fileLogos.length];
              return (
                <div
                  className="fourth box"
                  key={index}
                  onClick={() => {
                    if (item.file_type === "Files to Expire") {
                      getExpiryDate();
                    }
                  }}
                >
                  <img
                    src={fileLogo}
                    alt="nothing"
                    width="50"
                    className="filelogos"
                    height="50"
                  />
                  <div className="file-upload">
                    <h1>{item.file_type}</h1>
                    <p> file upload</p>
                    <span>{item.count}</span>
                  </div>
                </div>
              );
            })}
          </div>
          {open ? (
            <div className="expiry-popup-container">
              <div className="expiry-popup-outer">
                <div className="expiry-popup-heading">
                  <h2>Expiry Date Details</h2>
                  <CancelIcon
                    className="close-icon"
                    onClick={handleClose}
                  ></CancelIcon>
                </div>
                <div className="expiry-popup">
                  <div className="popup-div">
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Candidate Email</th>
                          <th>File Type</th>
                          <th>Remaining Days</th>
                          <th>Expiry Date</th>
                        </tr>
                      </thead>

                      <tbody>
                        {Array.isArray(visa) &&
                          visa.map((data, index) => (
                            <tr key={index}>
                              <td>{data.can_name}</td>
                              <td>{data.can_email}</td>
                              <td>{data.file_type}</td>
                             
                              <td>
                                {data.remaining_days <= 0
                                  ? "File  Expired"
                                  : `${data.remaining_days} ${
                                      data.remaining_days === 1 ? "Day" : "Days"
                                    }`}
                              </td>
                              <td>{formatDate(data.file_expiry_date)}</td>
                             
                            </tr>
                          ))}

                          {Array.isArray(i9) &&
                          i9.map((data, index) => (
                            <tr key={index}>
                              <td>{data.can_name}</td>
                              <td>{data.can_email}</td>
                              <td>{data.file_type}</td>
                          
                              <td>
                                {data.remaining_days <= 0
                                  ? "File Expired"
                                  : `${data.remaining_days} ${
                                      data.remaining_days === 1 ? "Day" : "Days"
                                    } `}
                              </td>
                              <td>{formatDate(data.file_expiry_date)}</td>
                            </tr>
                          ))}

                        {Array.isArray(coi) &&
                          coi.map((data, index) => (
                            <tr key={index}>
                              <td>{data.can_name}</td>
                              <td>{data.can_email}</td>
                              <td>{data.file_type}</td>
                            
                              <td>
                                {data.remaining_days <= 0
                                  ? "File  Expired"
                                  : `${data.remaining_days} ${
                                      data.remaining_days === 1 ? "Day" : "Days"
                                    } `}
                              </td>
                              <td>{formatDate(data.file_expiry_date)}</td>

                            </tr>
                          ))}

                      </tbody>
                    </table>

                    {/* previous code
                    {Array.isArray(coi) &&
                      coi.map((data, index) => {
                        return (
                          <div className="expiry-div" key={index}>
                            <div className="avatar-div">
                              <Avatar
                                alt={data.can_name}
                                src={CoiIcon}
                                sx={{ backgroundColor: "#00a0d3" }}
                              />
                            </div>
                            <div className="fileDetails">
                              {data.remaining_days <= 0 ? (
                                <p>
                                  <span className="can-name">
                                    {data.can_name}
                                  </span>
                                  's{" "}
                                  <span className="file-type">
                                    {data.file_type}
                                  </span>{" "}
                                  {data.remaining_days === 0
                                    ? "File Will Expiry"
                                    : " File Already Expired"}
                                  <span className="expiry-date">
                                    {data.remaining_days === 0
                                      ? " Today."
                                      : Math.abs(data.remaining_days)}{" "}
                                  </span>{" "}
                                  &nbsp;({formatDate(data.file_expiry_date)})
                                  {data.remaining_days === 0
                                    ? " "
                                    : " Days Ago"}{" "}
                                </p>
                              ) : (
                                <p>
                                  <span className="can-name">
                                    {data.can_name}
                                  </span>
                                  's{" "}
                                  <span className="file-type">
                                    {data.file_type}
                                  </span>{" "}
                                  File Will Expiry in{" "}
                                  <span className="expiry-date">
                                    {data.remaining_days}
                                  </span>{" "}
                                  {data.remaining_days === 1 ? "Day" : "Days"}{" "}
                                  &nbsp;(
                                  {formatDate(data.file_expiry_date)})
                                </p>
                              )}

                              <p>
                                Please Contact {data.can_name} Via This Email :{" "}
                                <span className="can-email">
                                  {data.can_email}
                                </span>
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    {Array.isArray(i9) &&
                      i9.map((data, index) => {
                        return (
                          <div className="expiry-div" key={index}>
                            <div className="avatar-div">
                              <Avatar
                                alt={data.can_name}
                                src={i9Icon}
                                sx={{ backgroundColor: "#e72a26" }}
                              />
                            </div>
                            <div className="fileDetails">
                              {data.remaining_days <= 0 ? (
                                <p>
                                  <span className="can-name">
                                    {data.can_name}
                                  </span>
                                  's{" "}
                                  <span className="file-type">
                                    {data.file_type}
                                  </span>{" "}
                                  {data.remaining_days === 0
                                    ? "File Will Expiry"
                                    : " File Already Expired"}
                                  &nbsp;{" "}
                                  <span className="expiry-date">
                                    {data.remaining_days === 0
                                      ? " Today."
                                      : Math.abs(data.remaining_days)}
                                  </span>{" "}
                                  {data.remaining_days === 0
                                    ? " "
                                    : " Days Ago"}{" "}
                                  ({formatDate(data.file_expiry_date)})
                                </p>
                              ) : (
                                <p>
                                  <span className="can-name">
                                    {data.can_name}
                                  </span>
                                  's{" "}
                                  <span className="file-type">
                                    {data.file_type}
                                  </span>{" "}
                                  File Will Expiry in{" "}
                                  <span className="expiry-date">
                                    {data.remaining_days}
                                  </span>{" "}
                                  {data.remaining_days === 1 ? "Day" : "Days"}{" "}
                                  &nbsp;(
                                  {formatDate(data.file_expiry_date)})
                                </p>
                              )}
                              <p>
                                Please Contact {data.can_name} Via This Email :{" "}
                                <span className="can-email">
                                  {data.can_email}
                                </span>
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    {Array.isArray(visa) &&
                      visa.map((data, index) => {
                        return (
                          <div className="expiry-div" key={index}>
                            <div className="avatar-div">
                              <Avatar
                                alt={data.can_name}
                                src={VisaIcon}
                                sx={{ backgroundColor: "#46166e" }}
                              />
                            </div>
                            <div className="fileDetails">
                              {data.remaining_days <= 0 ? (
                                <p>
                                  <span className="can-name">
                                    {data.can_name}
                                  </span>
                                  's{" "}
                                  <span className="file-type">
                                    {data.file_type}
                                  </span>{" "}
                                  {data.remaining_days === 0
                                    ? "File Will Expiry"
                                    : " File Already Expired "}
                                  <span className="expiry-date">
                                    {data.remaining_days === 0
                                      ? " Today."
                                      : Math.abs(data.remaining_days)}
                                  </span>{" "}
                                  &nbsp;({formatDate(data.file_expiry_date)})
                                  {data.remaining_days === 0
                                    ? " "
                                    : " Days Ago"}{" "}
                                </p>
                              ) : (
                                <p>
                                  <span className="can-name">
                                    {data.can_name}
                                  </span>
                                  's{" "}
                                  <span className="file-type">
                                    {data.file_type}
                                  </span>{" "}
                                  File Will Expiry in{" "}
                                  <span className="expiry-date">
                                    {data.remaining_days}
                                  </span>{" "}
                                  {data.remaining_days === 1 ? "Day" : "Days"}{" "}
                                  &nbsp;(
                                  {formatDate(data.file_expiry_date)})
                                </p>
                              )}
                              <p>
                                Please Contact {data.can_name} Via This Email :{" "}
                                <span className="can-email">
                                  {data.can_email}
                                </span>
                              </p>
                            </div>
                          </div>
                        );
                      })}  */}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}

      <Footerfile />
    </div>
  );
}

export default Dashboard;
